import {monthCalendar, weekCalendar} from './monthCalendar';
import {is_w1, is_w2} from '../global/functions';
export function displayAvailableAppointments() {

  const firstDate = global.ccUserInfo.firstAvailableDate;
  const dates = global.ccUserInfo.availableAndFullDates;
  const endDate = global.ccUserInfo.lastAvailableDate;


  // animates the whole calendar sliding down on step 3

  $('.loading-appointments').slideUp();
  $('.make-appointment').slideDown();

  if( is_w1() ) {
    //consultForm.hideWaitingIcon();
    $( '#webFormCalendar' ).slideDown();
  }




  // w1 stuff


  if(is_w1() ) {



    // Test: Monthly Calendar 5/3/16
    // Switch between calendar view or month view based on form class
    if( $( '#webFormCalendar' ).hasClass( 'cc-web-form-calendar-month' ) ) {
      if ( ! $( '.schedule-consultation-form' ).hasClass( 'monthly-schedule-form' ) ) {
        $( '.schedule-consultation-form' ).addClass( 'monthly-schedule-form' );
      }
      monthCalendar( 'webFormCalendar', firstDate.split( '-' )[1], firstDate.split( '-' )[0], 0, dates, endDate, false , false);
    } else {
      weekCalendar( 'webFormCalendar', firstDate.split( '-' )[1], firstDate.split( '-' )[0], 0, dates, endDate, false, false );
    }

    // Wait for step 2 accordant pixel to happen
    setTimeout( function() {
      // Accordant Pixel: ClearChoice Paid Form Step 3 Confirmation Page (User Completion of Select Date/Time)
      //var accordantLeadId = ccUserInfo.guid;
    }, 2000 );


  } else {


    monthCalendar( 'webFormCalendar', firstDate.split( '-' )[1], firstDate.split( '-' )[0], 0, dates, endDate, false, false );
    $('.field-agree-to-terms').show();
    $('.gform_button[type="submit"]').show();
    $('.gform_button[type="submit"]').attr("disabled", true);
    $('.gform_page_footer').append("<div class='no-appts-number'>Can't find an appointment that works with your schedule? Call us at <a href='tel:888-651-9950'>888-651-995</a></div>");


  }

}
