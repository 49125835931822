// import external dependencies
import 'jquery';

// Import everything from autoload
import "./autoload/_bootstrap.js"; import "./autoload/_collapse.js"

// import local dependencies
import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import postTypeArchiveGallery from './routes/gallery';
import healthAndConfidence from './routes/health-and-confidence';
import singleLocation from './routes/single-location';
import hasForm from './routes/form';
import templateLandingBoneloss from './routes/template-landing-boneloss';
import languageEs from './routes/language-es';
import pageConfirmationHandler from './routes/page-confirmation-handler';


/** Populate Router instance with DOM routes */
const routes = new Router({
  common,                   // All pages
  home,                     // Home page
  postTypeArchiveGallery,   // Gallery
  healthAndConfidence,      // Health-and-confidence
  hasForm,                  // Forms
  singleLocation,           // Single location
  templateLandingBoneloss,  // Bone Loss landing page
  languageEs,               // Spanish pages
  pageConfirmationHandler,             // Email Server Handling
});

// Load Events
jQuery(document).ready(() => routes.loadEvents());
