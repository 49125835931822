import queryString from 'query-string';

export function processAppointmentConfirmation(){
  var ajaxData = {};
  ajaxData.action = 'cc_salesforce_confirm_appointment';

  var parsed = queryString.parse(location.search);
  if(parsed.ID !== undefined){
    ajaxData.ID = parsed.ID;
  }

  if(parsed.id !== undefined){
    ajaxData.ID = parsed.id;
  }
  
  if(parsed.patientId !== undefined){
    ajaxData.patientId = parsed.patientId;
  }
  // ajaxData.requestId = getOauthRequestId();
  $.ajax({
    type: 'POST',
    url: ajaxurl,
    dataType: 'json',
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    data: ajaxData,
    success: function(msg) {
      if(msg.success != undefined && msg.success == 'false'){
        $('#successPanel').hide();
        $('#failedPanel').show();
      }
    },
    error: function() {
    },
  });
} 

export function initOhipSurvey(){
  retrieveQuestions();

  $(".submit-button").click(function(){
    submitQuestions();
  });

  $(".submit-button").mousedown(function(){
    $(".submit-button").addClass("submit-button-clicking");
  });

  $(".submit-button").mouseup(function(){
    $(".submit-button").removeClass("submit-button-clicking");
  });
}

function retrieveQuestions(){
  var ajaxData = {};
  ajaxData.action = 'cc_retrieve_ohip_survey_questions';

  // ajaxData.requestId = getOauthRequestId();
  $.ajax({
    type: 'POST',
    url: ajaxurl,
    dataType: 'json',
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    data: ajaxData,
    success: function(msg) {
      if(msg.success != undefined && msg.success == 'true'){
        renderQuestions(msg.questionInfo);
      }else{
        //render some error message
      }
    },
    error: function() {
    },
  });
}

function renderQuestions(questionJson){
  var questionContainer = $(".questions-rows");
  var currentBackgroundColor = "dark-row";
  $.each(questionJson,function(index,info){
    var html = "<div class='row "+currentBackgroundColor+"'>\
      <div class='question'>\
        <span>"+info.Text__c+"</span>\
      </div>\
      <div class='answer'>\
        <input type='radio' name='"+info.Text__c+"' value='Never' data-answer-value='1' data-question-id="+info.Id+">\
      </div>\
      <div class='answer'>\
        <input type='radio' name='"+info.Text__c+"' value='Seldom' data-answer-value='2' data-question-id="+info.Id+">\
      </div>\
      <div class='answer'>\
        <input type='radio' name='"+info.Text__c+"' value='Sometimes' data-answer-value='3' data-question-id="+info.Id+">\
      </div>\
      <div class='answer'>\
        <input type='radio' name='"+info.Text__c+"' value='Often' data-answer-value='4' data-question-id="+info.Id+">\
      </div>\
      <div class='answer'>\
        <input type='radio' name='"+info.Text__c+"'value='Always' data-answer-value='5' data-question-id="+info.Id+">\
      </div>\
    </div>";
    questionContainer.append(html);
    if(currentBackgroundColor == "dark-row"){
      currentBackgroundColor = "light-row";
    }else{
      currentBackgroundColor = "dark-row";
    }
  });
}

function submitQuestions(){
  $(".submit-button").val('Please Wait...');
  $(".submit-button").prop( "disabled", true );
  //error checking to make sure that all options were answered
  var names = {};
  $(':radio').each(function() {
      names[$(this).attr('name')] = true;
  });
  var count = 0;
  $.each(names, function() { 
    count++;
  });

  $(".no-answer").removeClass('no-answer');

  if ($(':radio:checked').length != count) {
    $.each(names,function(groupName,value){
      if(!$("[name='"+groupName+"']").is(':checked')){
        $("[name='"+groupName+"']").parent().parent().addClass("no-answer");
      }
    });

    $(".error").show();
    $('html, body').animate({
        scrollTop: $(".error").offset().top,
    }, 1000);

    $(".submit-button").val('Submit');
    $(".submit-button").prop( "disabled", false );
    return false;
  }else{
    $(".error").hide();
  }

  var ajaxData = {};
  
  var parsed = queryString.parse(location.search);
  console.log(parsed);
  if(parsed.surveyVersion !== undefined){
    ajaxData.surveyVersion = parsed.surveyVersion;
  }

  if(parsed.opportunityid !== undefined){
    ajaxData.opportunityid = parsed.opportunityid;
  }

  var questionsFormatted = [];
  $(':radio:checked').each(function(){
    questionsFormatted.push({'answerText':$(this).val(),'answerValue':$(this).data('answer-value'),'questionId':$(this).data('question-id')});
  });

  var objMap = {"questionInfo" : questionsFormatted};
  ajaxData.questionInfo = JSON.stringify(objMap);

  ajaxData.action = 'cc_submit_ohip_survey_questions';

  // ajaxData.requestId = getOauthRequestId();
  $.ajax({
    type: 'POST',
    url: ajaxurl,
    dataType: 'json',
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    data: ajaxData,
    success: function(msg) {
      if(msg.success != undefined && msg.success == 'true'){
        $(".success-message").show();
        $("#ohip-questions").hide();
      }else{
        $(".failure-message").show();
        $("#ohip-questions").hide();
      }
    },
    error: function() {
    },
  });

}

export function initNpsSurveryHandler(){
  $( document ).ready(function() {
    processNpsSurveryScore();
    $('.submit-button').click(function(){
      console.log("hello world");
      submitNpsSurveryComment();
    });

    $(".submit-button").mousedown(function(){
      $(".submit-button").addClass("submit-button-clicking");
    });

    $(".submit-button").mouseup(function(){
      $(".submit-button").removeClass("submit-button-clicking");
    });

  });
}

function submitNpsSurveryComment(){

  if($("#comment-text-input").val() == undefined || $("#comment-text-input").val() == ""){
    $("#error").show();
    return false;
  }else{
    $("#error").hide();
  }

  if(global.recordId != undefined){
    var ajaxData = {};
    ajaxData.action = 'cc_salesforce_process_nps_comment';

    ajaxData.recordId = global.recordId;
    ajaxData.textInfo = $("#comment-text-input").val();

    $.ajax({
      type: 'POST',
      url: ajaxurl,
      dataType: 'json',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      data: ajaxData,
      success: function(msg) {
        console.log(msg);
      },
      error: function() {
      },
    });
  }

  $("#initial-content").hide();
  $("#thank-you-content").show();
}

function processNpsSurveryScore(){
  var ajaxData = {};
  ajaxData.action = 'cc_salesforce_process_nps_survey';

  var parsed = queryString.parse(location.search);
  if(parsed.oid !== undefined){
    ajaxData.oid = parsed.oid;
  }

  if(parsed.nps !== undefined){
    ajaxData.nps = parsed.nps;
  }

  ajaxData.userIp = $("#userIpAddress").val();
  
  // ajaxData.requestId = getOauthRequestId();
  $.ajax({
    type: 'POST',
    url: ajaxurl,
    dataType: 'json',
    contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
    data: ajaxData,
    success: function(msg) {
      console.log(msg);
      if(msg.success != undefined && msg.success == "true"){
        global.recordId = msg.id;
      }
    },
    error: function() {
    },
  });
}