export default {
  init() {

    /**
     * Scroll to an (ID) element with some offset to compensate for the mobile menu being in the way
     */


    let buttons = $('ul.single-location--all-buttons li a');

    buttons.click(function(e){

      // size of the menu/logos/etc to offset
      let offset = 240;
      // ID to scroll to (which is defined by the href)
      let target = $(this.getAttribute('href'));

      // scroll to the ID
      if( target.length ) {
        e.preventDefault();
        $('html, body').stop().animate({
          scrollTop: target.offset().top - offset,
        }, 1000);
      }

    });

    let anchorbuttons = $('.has-carets a');
    anchorbuttons.click(function(e){
      // size of the menu/logos/etc to offset

      var offset = 180;
      if($(window).width() < 950){
        offset = 108;
        if($(this).attr('href') == '#about-the-center'){
          offset = 121;
        }
      }
      

      // ID to scroll to (which is defined by the href)
      let target = $(this.getAttribute('href'));
      // scroll to the ID
      if( target.length ) {
        e.preventDefault();
        $('html, body').stop().animate({
          scrollTop: target.offset().top - offset,
        }, 500);

      }
    });

  },
};
