import moment from 'moment';
import {is_w1, is_w2} from '../global/functions';
import Cookie from "js-cookie";

function getMonthName(month) {
  const monthArray = new Array(
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
    );
  return monthArray[ ( month - 1 ) ];
}

export function monthCalendar(ElemId, month, year, weekOffset, dates, endDate, past, showprev) {
  $("td").removeClass( 'promptToCall' );
  $("td").unbind('click','showPromptToCallMessageBox');
  
  let datesArr = [];

  // Populate Appointment information with what we have so far
  const selectedCenter = global.ccUserInfo.availableCenters[global.ccUserInfo.centerID];
  global.ccUserInfo.addressString = `<h6>ClearChoice ${selectedCenter.name} Center</h6>`;
  global.ccUserInfo.addressString += `<p>${selectedCenter.address} `;
  global.ccUserInfo.addressString += `${selectedCenter.address2}<br/>`;
  global.ccUserInfo.addressString += `${selectedCenter.city}, ${selectedCenter.state} `;
  global.ccUserInfo.addressString += `${selectedCenter.zipcode}</p>`;
  // const addressHTML = global.ccUserInfo.addressString;
  $( '#confirmation-details .name' ).html( `${global.ccUserInfo.firstName} ${global.ccUserInfo.lastName}`);
  $( '#confirmation-details .date' ).html( '<span>Select a date on the calendar</span>' );
  $( '#confirmation-details .address' ).html( global.ccUserInfo.addressString );

  if( is_w1() ) {
    // Move term agreement and submit field into right column
    $( '.monthly-schedule-form .field-agree-to-terms' ).appendTo( '#webFormCalendar.cc-web-form-calendar-month .right-wrapper' );
    $( '.monthly-schedule-form .page-datetime-confirm .gform_page_footer' ).appendTo( '#webFormCalendar.cc-web-form-calendar-month .right-wrapper' );
    $( '.make-appointment' ).addClass( 'disabled-button' );
    if ( $( '#temp_submit_button' ).length === 0 ) {
      $( '.month-calendar-right .right-wrapper' ).append( '<input type="button" id="temp_submit_button" insp-form-input-id="submit-button" class="gform_next_button button" value="Schedule My Free Consultation" tabindex="21" >');
    }
  }

  const today = new Date();
  // const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  if ( typeof( weekOffset ) === 'undefined' || weekOffset === null ) {
    weekOffset = 0;
  }
  if ( typeof( month ) === 'undefined' || month === null ) {
    month = today.getMonth() + 1;
  }
  if ( typeof( year ) === 'undefined' || year === null ) {
    year = today.getFullYear();
  }
  if ( typeof( dates ) === 'undefined' ) {
    datesArr = null;
  } else {
    for ( const key in dates ) {
      datesArr.push( dates[ key ].date );
    }
  }

  if ( typeof( past ) === 'undefined' ) {
    past = true;
  }
  const calendarDate = new Date( year, ( month - 1 ), 1, 0, 0, 0, 0 );
  const tempDate = new Date( year, ( month - 1), 1, 0, 0, 0, 0 );
  // const lastDate = new Date( year, month, 0, 0, 0, 0, 0 );
  const day = calendarDate.getDay();

  var stringDate = `${getMonthName( month )}, ${year}`;
  global.dataLayer.push({ 'currentMonth': getMonthName( month )});

  const oElem = $( `#${ElemId} table` );
  const oButtons = $( `#${ElemId} a.btnPrev, #${ElemId} a.btnNext` );
  if ( past === false && ( year < today.getFullYear() || ( year === today.getFullYear() && month <= ( today.getMonth() + 1 ) ) ) ) {
    $( `#${ElemId} a[title="Last Month"]` ).hide();
  } else {
    $( `#${ElemId} a[title="Last Month"]` ).show();
  }
  const oTd = oElem.find( 'td' );
  oTd.html( '&nbsp;' ).removeClass( 'currentMonth' ).removeClass( 'currentDay' ).removeClass( 'selected' ).removeClass( 'CalActive' );
  const oH4 = $( `#${ElemId} .choose-date-month h4` );

  //add logic for doing this conditionally
  var navigationButtonTest = Cookie.get('test-navigation-button');
  if(navigationButtonTest != undefined && navigationButtonTest == 'true'){
    stringDate = `<span class="blue-month-name-calendar">${getMonthName( month )}</span></br><span class="year-string-calendar">${year}</span>`;
  }
  
  oH4.html( stringDate );
  oElem.data( 'month', month );
  oElem.data( 'year', year );

  if ( oElem.data( 'boundEvents' ) === null || oElem.data( 'boundEvents' ) === undefined ) {
    oTd.bind( 'click', function() {
      if( $( this ).hasClass( 'CalActive' ) ) {
        oTd.removeClass( 'selected' );
        $( this ).addClass( 'selected' );
      }
    } );
    oElem.data( 'boundEvents', true );
  }
  oButtons.unbind( 'click' );

  oButtons.bind( 'click', function() {
    // const _datesArr = datesArr;
    function pad( n ) {
      if ( n < 10 ) {
        return `0${n}`;
      } else {
        return n;
      }
    }
    let tmpMonth = oElem.data( 'month' );
    let tmpYear = parseInt( oElem.data( 'year' ) );

    if ( $( this ).hasClass( 'btnPrev' ) ) {
      tmpMonth--;
      if ( tmpMonth <= 0 ) {
        tmpMonth = 12;
        tmpYear--;
      }
      tmpMonth = pad( tmpMonth );
      monthCalendar( ElemId, tmpMonth, tmpYear, 0, dates, endDate, past, false );
    } else {
      tmpMonth++;
      if ( tmpMonth >= 13 ) {
        tmpMonth = 1;
        tmpYear++;
      }
      tmpMonth = pad( tmpMonth );
      monthCalendar( ElemId, tmpMonth, tmpYear, 0, dates, endDate, past, true );
    }
  } );

  let n = 0;
  tempDate.setDate( 0 - day );

  while( n < oTd.length ) {

    tempDate.setDate( tempDate.getDate() + 1 );

    oTd.eq( n ).html( tempDate.getDate() );

    const yearString = tempDate.getFullYear();
    oTd.eq( n ).data( 'year',yearString );

    let monthString = tempDate.getMonth() + 1;
    monthString = `00${monthString}`;
    monthString = monthString.substring( monthString.length - 2, monthString.length );
    oTd.eq( n ).data( 'month', monthString );

    let dateString = tempDate.getDate();
    dateString = `00${dateString}`;
    dateString = dateString.substring( dateString.length - 2, dateString.length );

    // Set <td> 'data-day'
    oTd.eq( n ).data( 'day', dateString );

    // Adjust dateString
    dateString = `${yearString}-${monthString}-${dateString}`;

    // Reset TD classes
    oTd.eq( n ).addClass('currentMonth').removeClass( 'CalFull' );

    // Test Case Specific - slides up the time selector
    oTd.eq( n ).removeClass( 'inactiveMonth' );

    if( is_w1() ) {
        $('#webFormCalendar .choose-date-timelist').slideUp();
    }

    if ( tempDate.getMonth() == calendarDate.getMonth() ) {
      oTd.eq( n ).addClass( 'currentMonth' );

      if ( datesArr === null ) {
        oTd.eq( n ).addClass( 'CalActive' );
      } else if( $.inArray( dateString, datesArr ) >= 0 ) {
        oTd.eq( n ).addClass( 'CalActive' );
      }

      //Add "full" class to full days
      const dateStr = dateString.replace( /-/g, '/');

      if ( typeof dates[ dateStr ] != 'undefined' ) {

        // Checking for same-day scheduling
        if ( tempDate.getDate() === today.getDate() ) {
          let todayFull = true;

          if ( global.ccUserInfo.marketScore === 'GYIM' || global.ccUserInfo.leadSegment == 4 || global.ccUserInfo.leadSegment == 5) {
            const apps = dates[ dateStr ].appointmentSlots;

            for ( let i = apps.length - 1; i >= 0; i-- ) {
              // Building moment.js string from appt date/time
              const time = apps[i].start;

              const wholeTime = time.replace( ' ', '.0' );
              const thisDay = apps[i].date;
              const finalDate = `${thisDay} ${wholeTime}`;

              // Appointment is more than 2 hours away & has a block (it's available)
              if ( ( moment( finalDate ) - moment( today ) > 7200000 ) && apps[i].blocks.length ) {

                // Set flag to false ( so we don't "block" off today );
                todayFull = false;
              } else {

                // Set block to "empty/scheduled"
                apps[i].blocks = [];
              }
            }
          }

          // If todayFull is still true ( no appts match "more than 2 hours in the future" AND "still open in SF" )
          if ( todayFull ) {
            oTd.eq( n ).removeClass( 'CalActive' ).addClass( 'CalFull' );
          }
        }
        // End Same-Day Schedule adjustments

        // If day is full:
        if ( dates[ dateStr ].full ) {
          oTd.eq( n ).removeClass( 'CalActive' ).addClass( 'CalFull' );
        }

        if ( dates[ dateStr ].promptToCall != undefined &&  dates[ dateStr ].promptToCall == true) {
          oTd.eq( n ).addClass('promptToCall');
        }
      }

    } else {
      oTd.eq( n ).addClass( 'inactiveMonth' );
    }

    if( tempDate.getMonth() == today.getMonth() && tempDate.getDate() == today.getDate() ) {
      oTd.eq( n ).addClass( 'currentDay' );
      $( `#${ElemId} a.btnNext` ).show();
    }

    if( $( 'td.currentDay' ).length > 0 ) {
      $( `#${ElemId} a.btnPrev` ).hide();
    } else {
      $( `#${ElemId} a.btnPrev` ).show();
    }

    if( tempDate.getMonth() == endDate.getMonth() && tempDate.getDate() == endDate.getDate() ) {
      oTd.eq( n ).addClass( 'endDay' );

      // Only hide next button if the endDate isn't also in an inactive month row (extra row that will be hidden if it's all "inactiveMonth" class)
      if( ! oTd.eq(n).hasClass( 'inactiveMonth' ) ) {
        $( `#${ElemId} a.btnNext` ).hide();
      }
    }
    n++;
  }

  if(showprev){
    $( `#${ElemId} a.btnPrev` ).show();
  }

  // Hide the last <tr> if all of the dates in the row are ".inactiveMonth"
  oElem.find( 'tr' ).each( function() {
    if ( $( this ).find( 'td.inactiveMonth' ).length === 7 ) {
      $( this ).hide();
    } else {
      $( this ).show();
    }
  } );
}



/**
 * This function calls the view for the calendar to select appointment dates
 *
 * @param  string 	ElemId
 * @param  string 	month
 * @param  string 	year
 * @param  string 	weekOffset
 * @param  string 	dates
 * @param  string 	endDate
 * @param  boolean 	past
 *
 * @return null
 */
export function weekCalendar(ElemId, month, year, weekOffset, dates, endDate, past, showprev) {

  var today = new Date();
  var monthNames = [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ];

  if(typeof(weekOffset) === "undefined" || weekOffset === null) {
    weekOffset = 0;
  }
  if(typeof(month) === "undefined" || month === null) {
    month = today.getMonth()+1;
  }
  if(typeof(year) === "undefined" || year === null) {
    year = today.getFullYear();
  }
  if(typeof(dates) === "undefined") {
    dates = null;
  }
  if(typeof(past) === "undefined") {
    past = true;
  }

  var calendarDate = new Date(year, (month-1), 1, 0, 0, 0, 0);
  var tempDate = new Date(year, (month-1), 1, 0, 0, 0, 0);
  //var lastDate = new Date(year, month, 0, 0, 0, 0, 0);
  var day = calendarDate.getDay();

  var stringDate = getMonthName(month) + ', ' + year;

  var oElem = $('#'+ElemId+' table');
  var oButtons = $('#'+ElemId+' a.btnPrev, #'+ElemId+' a.btnNext');

  if( weekOffset <= -2 || past === true ) {
    $('#'+ElemId+' a.btnPrev').hide();
  } else {
    $('#'+ElemId+' a.btnPrev').show();
  }

  $('#'+ElemId+' a.btnNext').show();

  var oTd = oElem.find('td');
  oTd.html('&nbsp;').removeClass("currentMonth").removeClass("currentDay").removeClass("selected").removeClass('CalActive');
  var oH4 =$('#'+ElemId+' h4');
  oH4.html(stringDate);

  oElem.data('month', month);
  oElem.data('year', year);

  if(oElem.data('boundEvents') === null || oElem.data('boundEvents') === undefined) {
    oTd.bind('click', function(){
      if($(this).hasClass('CalActive')) {
        oTd.removeClass("selected");
        $(this).addClass('selected');
      }
    });

    oElem.data('boundEvents', true);
  }

  oButtons.unbind('click');
  oButtons.bind('click', function() {
    //var _dates = dates;
    var tmpMonth = oElem.data('month');
    var tmpYear = oElem.data('year');
    if( $(this).hasClass("btnPrev") ) {
      //tmpMonth--;
      weekOffset--;
      if(tmpMonth <= 0){
        tmpMonth = 12;
        tmpYear--;
      }
      weekCalendar(ElemId, tmpMonth, tmpYear, weekOffset, dates, endDate, past, false);
    } else {
      //tmpMonth++;
      weekOffset++;
      if(tmpMonth >= 13){
        tmpMonth = 1;
        tmpYear++;
      }
      weekCalendar(ElemId, tmpMonth, tmpYear, weekOffset, dates, endDate, past, true);
    }
  });


  var n = 0;

  var firstDateArray = dates[0].split("-");
  var firstDateYear = firstDateArray[0];
  var firstDateMonth = monthNames[firstDateArray[1] - 1];
  var firstDateDay = firstDateArray[2];

  var firstDateString = firstDateMonth + " " + firstDateDay + ", " + firstDateYear;

  var firstDateObj = new Date(firstDateString);

  var offset = ( ( ( ( firstDateObj.getDate() + day ) / 7 ) | 0 ) + weekOffset ) * 7;

  tempDate.setDate(0-day + offset );

  while(n < oTd.length){

    tempDate.setDate(tempDate.getDate()+1);

    oTd.eq(n).html("<span>" + monthNames[tempDate.getMonth()] + "</span><br/>" + tempDate.getDate());

    var yearString = tempDate.getFullYear();
    oTd.eq(n).data('year',yearString );
    var monthString = tempDate.getMonth()+1;
    monthString = '00'+monthString;
    monthString = monthString.substring(monthString.length-2,monthString.length);
    oTd.eq(n).data('month', monthString);
    var dateString = tempDate.getDate();
    dateString = '00'+dateString;
    dateString = dateString.substring(dateString.length-2,dateString.length);
    oTd.eq(n).data('day',dateString);
    dateString = yearString+'-'+monthString+'-'+dateString;

    oTd.eq(n).addClass('currentMonth');
    if(!dates) {
      oTd.eq(n).addClass('CalActive');
    } else if($.inArray(dateString, dates) >= 0) {
      oTd.eq(n).addClass('CalActive');
    }

    if(tempDate.getMonth() === today.getMonth() && tempDate.getDate() === today.getDate() ) {
      oTd.eq(n).addClass('currentDay');
    }

    if(tempDate.getMonth() === endDate.getMonth() && tempDate.getDate() === endDate.getDate() ) {
      oTd.eq(n).addClass('endDay');
      $('#'+ElemId+' a.btnNext').hide();
    }
    n++;
  }

  if(showprev){
    $('#'+ElemId+' a.btnPrev').show();
  }
}
