import Cookie from "js-cookie";
import {helpers} from '../global/setUserParamsLegacy';
import {is_w1, is_w2} from '../global/functions';


 
/**
 * This method shows and adds the confirmation details block (for w1)
 *
 * @return null
 */
export function addConfirmationDetails( formID ) {

  $( '#confirmation-details .appt-details .date' ).html( global.ccUserInfo.appointmentString );

  // Test: Monthly Calendar 5/3/16
  if ( ! $( '#webFormCalendar' ).hasClass( 'cc-web-form-calendar-month' ) ) {

    // Keep this section after the test is over
    var selectedCenter = ccUserInfo.availableCenters[ccUserInfo.centerID];
    global.ccUserInfo.addressString = '<h6>ClearChoice ' + selectedCenter.name + ' Center</h6>';
    global.ccUserInfo.addressString += '<p>' + $( '.callToday a' ).first().text() + '</p>';
    global.ccUserInfo.addressString += '<p>' + selectedCenter.address + ' ';
    global.ccUserInfo.addressString += selectedCenter.address2 + '<br/>';
    global.ccUserInfo.addressString += selectedCenter.city + ', ' + selectedCenter.state + ' ';
    global.ccUserInfo.addressString += selectedCenter.zipcode +	'</p>';

    var addressHTML = global.ccUserInfo.addressString;

    $( '#confirmation-details .appt-details .address' ).html( addressHTML );
    if ( $( '#temp_submit_button' ).length === 0 && $( '#gform_page_' + formID + '_' + ccUserInfo.currentStep ).is( ':last-child' ) ) {
      $( '.month-calendar-right .right-wrapper' ).append( '<input type="button" id="temp_submit_button" insp-form-input-id="submit-button" class="gform_next_button button" value="Schedule My Free Consultation" tabindex="21" >');
    }

    $( '#confirmation-details' ).slideDown();
  }
  // End Test 5/3/16

}

/**
 * returns default empty list for global.ccUserInfo
 */
export function getDefaultCCUserInfo() {
  return {
    guid: null,
    contactID: null,
    accountID: null,
    firstName: null,
    lastName: null,
    phone: null,
    email: null,
    address: null,
    zipcode: null,
    city: null,
    state: null,
    centerID: null,
    availableCenters: {},
    availableAppointments: {},
    appointment: null,
    reschedulingAppointment: false,
    fewQuestions: {},
    trans_id : null, // used for ADI page
    referral_id : null, // this is the ID from the Dental_Practice__c table
    page: null,
    testing: null,
  };
}



/**
 * Sets global.ccUserInfo from the data received from the Partial form
 */
export function setGlobalFormData() {
  if (Cookie.get('cc-pre-form')) {
    const preFormData = getPreFormData();

    if (preFormData) {
      global.ccUserInfo.firstName = preFormData.find(preFormData => preFormData.name === 'input_28').value;
      global.ccUserInfo.lastName = preFormData.find(preFormData => preFormData.name === 'input_27').value;
      global.ccUserInfo.phone = preFormData.find(preFormData => preFormData.name === 'input_2').value;
      global.ccUserInfo.email = preFormData.find(preFormData => preFormData.name === 'input_3').value;
      global.ccUserInfo.address = preFormData.find(preFormData => preFormData.name === 'input_4').value;
      global.ccUserInfo.zipcode = preFormData.find(preFormData => preFormData.name === 'input_5').value;
      global.ccUserInfo.city = preFormData.find(preFormData => preFormData.name === 'input_6').value;
      global.ccUserInfo.state = preFormData.find(preFormData => preFormData.name === 'input_36').value;
    }
  }


  global.ccUserInfo.page = $('body.adi-schedule').length > 0 ? 'adi' : 'os';

  // Sets the ID of the ADI center if the url param 'trans_id' exists @todo: use getParam() instead
  let p = 'trans_id'; // url key name
  let match = RegExp('[?&]' + p + '=([^&]*)').exec(window.location.search);
  let p2 = 'referral_id'; // url key name
  let match2 = RegExp('[?&]' + p2 + '=([^&]*)').exec(window.location.search);

  if(match)
    global.ccUserInfo.trans_id = decodeURIComponent(match[1].replace(/\+/g, ' '));
  if(match2)
    global.ccUserInfo.referral_id = decodeURIComponent(match2[1].replace(/\+/g, ' '));

}


// set Google Analytics info for pushing to Sales Force
export function setGlobalGAInfo() {
    if (typeof ga !== 'undefined') {
      ga(function () {
        const tracker = ga.getAll()[0];
          global.ga.trackingId = tracker.get('trackingId');
          global.ga.clientId = tracker.get('clientId');
          global.ga.userId = Cookie.get('userId');
          global.ga.gclId = Cookie.get('cc-gclid');
        });
      } else {
          if(global.ga != undefined){
            global.ga.trackingId = global.ga.clientId = global.ga.userId = global.ga.gclId = '';
          }
    }
}


/**
 * Get user submitted data from Cookie (created from Partial form submission)
 */
export function getPreFormData() {
  return $.parseJSON(window.atob(Cookie.get('cc-pre-form')));
}



/**
 * Sets the `cc-pre-form` cookie that contains all the user submitted data from the Partial form
 */
export function setPreFormData(form) {
  Cookie.set('cc-pre-form', window.btoa(JSON.stringify((form).serializeArray())));
}


 /**
  * sets checkbox and corresponding label states based on check settings
  */
  // all checkboxes other than none of the above
export var checkboxState = function() {
  var noneOfTheAbove = $(this).closest("ul").find("input[value='None of the Above']");

  if ( $(this).is(':checked') ) {
    $(this).parent().find('label').first().css('font-weight', 600);

    if ( noneOfTheAbove.is(':checked') ) {
      noneOfTheAbove.prop('checked', false);
      noneOfTheAbove.parent().find('label').first().css('font-weight', 400);
    }

  } else {
    $(this).parent().find('label').first().css('font-weight', 400);
  }
}

// none of the above checkboxes
export var noneOfTheAboveState = function() {
  var otherLocalCheckboxes = $(this).closest("ul").find('input[type=checkbox]').not($(this));

  if ( $(this).is(':checked') ) {
    $(this).parent().find('label').first().css('font-weight', 600);

    // uncheck otherLocalCheckboxes and normalize font-weight
    otherLocalCheckboxes.prop('checked', false);
    otherLocalCheckboxes.each( function() {
      $(this).parent().find('label').first().css('font-weight', 400);

      // hides any list items a checkbox might display when clicked
      $(this).parent().find('li').hide();
    });
  } else {
    $(this).parent().find('label').first().css('font-weight', 400);
  }
}
/**
   * Shows the message box for form completion errors
   *
   * @return null
   */
export function showMessageBox(){
  if( is_w1() ) {
    var $messageBox = $('#webform-component-messages');
    $('#webform-component-messages .call-me-now-box').css('display', 'none');
    $('#webform-component-messages .message-container').css('display', 'block');
    $messageBox.addClass('open').fadeIn();
  } else {
    $( '#web-form-modal' ).modal();
  }
}

/**
   * Hides message box for form completion errors
   *
   * @return null
   */
export function hideMessageBox(id = '' ) {
  if( is_w1() ) {
    var $messageBox = $( '#webform-component-messages' );
    $messageBox.removeClass( 'open' ).fadeOut();
  } else {
    $(id).modal("toggle");
  }
}

/**
 * This method shows the "Call me now" module
 *
 * @return null
 */
export function showCallMeNow() {
  var $callmenowFirstName = $( '#call-me-now-firstName' );
  var $callmenowLastName = $( '#call-me-now-lastName' );
  var $callmenowPhone = $( '#call-me-now-phone' );

  $callmenowFirstName.val( global.ccUserInfo.firstName );
  $callmenowLastName.val( global.ccUserInfo.lastName );
  $callmenowPhone.val( global.ccUserInfo.phone );
  

  var $messageBox = $('#webform-component-messages');
  $('#webform-component-messages .call-me-now-box').css('display', 'block');
  $('#webform-component-messages .waiting-icon-container').css('display', 'none');
  $('#webform-component-messages .message-container').css('display', 'none');
  $messageBox.addClass('open').fadeIn();

  validateSection( 'call-me-now-section' );
  //initiate continue button
}
export function loadCallMeNowForm() {
  var $callmenowFirstName = $( '#call-me-now-firstName' );
  var $callmenowLastName = $( '#call-me-now-lastName' );
  var $callmenowPhone = $( '#call-me-now-phone' );
  var $messageBox = $('#call-me-now-form');
  $messageBox.find(".buttons .cancel").click(function(){
    hideMessageBox('#call-me-now-form');
  });

  $messageBox.find(".buttons .continue").click(function(){
    $messageBox.find(".buttons .continue").html("Please Wait...");
    $('.call-me-now-form-error').remove();
    //Validate the section and collect the errors
    var errors = validateSection( "call-me-now" );

    if(errors.length === 0){

      // $messageBox.find(".waiting-icon-container").slideDown(animationDuration);
      $messageBox.find(".call-me-now-form").slideUp();

      var leadData = {};

      leadData.action = "cc_salesforce_initiate_call_me_now";

      //If they're just repeating step one.
      if(global.ccUserInfo.guid !== ''){
        leadData.GUID = global.ccUserInfo.guid;
      }

      leadData.FirstName = $callmenowFirstName.val();
      leadData.LastName = $callmenowLastName.val();
      leadData.Phone = $callmenowPhone.val();

      if( global.ccUserInfo.address ){
        leadData.Address = global.ccUserInfo.address;
      }
      if( global.ccUserInfo.city ){
        leadData.City = global.ccUserInfo.city;
      }
      if( global.ccUserInfo.state ){
        leadData.State = global.ccUserInfo.state;
      }
      if( global.ccUserInfo.zipcode ){
        leadData.Zip = global.ccUserInfo.zipcode;
      }

      if( global.ccUserInfo.email !== ''){
        leadData.Email = global.ccUserInfo.email;
      }

      if( global.ccUserInfo.availableCenters && global.ccUserInfo.centerID){
        var currentCenterData = global.ccUserInfo.availableCenters[ global.ccUserInfo.centerID ];
        leadData.CenterId = currentCenterData.id;
      }
      leadData.testing = window.ccUserInfo.testing;

      jQuery.ajax({
        type: "POST",
        url: ajaxurl,
        data: leadData,
        success: function(){
          $messageBox.find(".message-text").html("<p>Thank you! You've successfully submitted your request. We look forward to speaking with you shortly.</p>");
          $messageBox.find('.buttons').html('<div class="row"><button class="btn btn-sm btn-outline-primary px-3 py-2 temp dismiss">Ok</a></div>');
          $messageBox.find(".buttons .dismiss").click(function(){
            hideMessageBox('#call-me-now-form');
          });

        },
        error: function(msg){
          // $messageBox.find(".waiting-icon-container").slideUp(animationDuration);
          $messageBox.find(".message-text").append("<p style='color: red;'>Sorry, something went wrong with your request. Try again later.</p>").slideDown();
          $messageBox.find('.buttons').html('<div class="row"><button class="btn btn-sm btn-outline-primary px-3 py-2 temp dismiss">Ok</a></div>');
          $messageBox.find(".buttons .dismiss").click(function(){
            hideMessageBox('#call-me-now-form');
          });

          global.dataLayer.push({ 'error_data': msg });
          global.dataLayer.push({ 'error_message': 'Ajax Error initiating Call-Me-Now' });
          global.dataLayer.push({ 'event': 'ajax-error' });
        },
      });
    }else{
      $messageBox.find(".buttons .continue").html("Continue");
      $.each(errors,function(index,error){
        $messageBox.find(".message-text").append('<div class="validation_error call-me-now-form-error">'+error+'</div>');
      });
    }
  });
}


/**
 * Hide "waiting" icon
 *
 * @return null
 */
export function hideWaitingIcon()	{
  var $loadingIcon = $( '#waiting-icon' );
  $loadingIcon.hide();
}

/**
 * Show "waiting" icon
 *
 * @return null
 */
export function showWaitingIcon( message ) {
  var $loadingIcon = $( '#waiting-icon' );
  $loadingIcon.find( '.message' ).html( message );
  $loadingIcon.show();
}

/**
 * Validate form sections before passing to next form section/page
 *
 * @param  string   Passin the section string/name here
 *
 * @return object   Returns an error object for invalid sections
 */
export function validateSection(section) {
  var errors = [];
  let callCenterNumber = $(".trans_id").first().text() || '888-651-9950';

  switch(section){
    case 'page-gather-info':
      $( '.page-gather-info' ).find( 'input' ).removeClass( 'error' );

      if ( $( '.field-firstName input' ).val() === '' ) {
        errors.push( 'Please enter your first name.' );
        $( '.field-firstName input' ).addClass( 'error' );
      }

      if ( $('.field-lastName input' ).val() === '' ) {
        errors.push( 'Please enter your last name.' );
        $('.field-lastName input' ).addClass( 'error' );
      }

      var phonenumberVal = $( '.field-phone input' ).val();
      var phonenumber = phonenumberVal.replace(/\D/g,'');

      if ( phonenumber === '' ) {
        errors.push( 'Please enter your phone.' );
        $( '.field-phone input' ).addClass( 'error' );
      }

      if ( phonenumber.length !== 10 ) {
        errors.push( 'Only 10-digit phone numbers are supported.' );
        $( '.field-phone input' ).addClass( 'error' );
      }

      if ( phonenumber != undefined && phonenumber.length == 10 && (phonenumber[0] == "1" || phonenumber[0] == "0")) {
        errors.push( 'Not a valid phone number' );
        $( '.field-phone input' ).addClass( 'error' );
      }

      var emailVal = $( '.field-email input' ).val();
      if ( emailVal === '' ) {
        errors.push( 'Please enter your email.' );
        $( '.field-email input' ).addClass( 'error' );
      } else {

        var patt = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if( ! patt.test( emailVal.trim() ) ) {
          errors.push( 'That doesn&rsquo;t seem to be a valid email address' );
          $( '.field-email input' ).addClass( 'error' );
        }
      }

      if ( $( '.field-address input' ).val() === '' ) {
        errors.push( 'Please enter your address.' );
        $( '.field-address input' ).addClass( 'error' );
      }

      if ( $( '.field-zipcode input' ).val() === '' ) {
        errors.push( 'Please enter your zipcode.' );
        $( '.field-zipcode input' ).addClass( 'error' );
      } else if ( $( '.field-zipcode input' ).hasClass('zipcode-error') ) {
        errors.push( 'Please enter a valid zipcode.' );
        $( '.field-zipcode input' ).addClass( 'error' );
      }

      if ( $( '.field-city input' ).val() === '' ) {
        errors.push( 'Please enter your city.' );
        $( '.field-city input' ).addClass( 'error' );
      }

      if ( $( '.field-state input' ).val() === '' ) {
        errors.push( 'Please enter your state.' );
        $( '.field-state input' ).addClass( 'error' );
      }

      break;
    case 'page-few-questions':
      if ( $( '.field-questions input:checked' ).length < 1 ) {
        errors.push( 'Please select at least one option' );
      }

      if ( $( '.field-questions input:checked' ).val() === 'None of the Above' ) {
        errors.push( 'Based on your selection, it doesn&rsquo;t look like you&rsquo;re a candidate for dental implants. But if you&rsquo;d like to talk to someone about it, call us at ' + callCenterNumber + '' );
      }

      break;

    case 'page-datetime-confirm':
      $( '.page-datetime-confirm' ).find( 'input' ).removeClass( 'error' );

      if ( ! $( '.field-agree-to-terms input' ).prop( 'checked' ) ){
        errors.push( 'To continue, you must agree to the terms and conditions.' );
        $( '.field-agree-to-terms input' ).addClass( 'error' );
      }

      var selectedTimeSlot = $("#choose-date--timelist .choose-date-timelist ul > li.selected").data('timeslot');
      if(selectedTimeSlot == undefined || selectedTimeSlot == ""){
        errors.push( 'Please select a date and time.' );
      }

      // error checking for when birthdate is required
      // if ($( '#patient-birth-date' ).val() === ''){
      //   errors.push( 'Please enter a proper date of birth.' );
      // } else if(!checkFor18AndOlder()){
      //   errors.push( 'Must be 18 or older to submit form.' );
      // }
      if($( '#patient-birth-date' ).val() != '' && $( '#patient-birth-date' ).val() != undefined){
        if(!checkFor18AndOlder()){
          errors.push( 'Must be 18 or older to submit form.' );
        }
      }
      

      break;

    case 'page-datetime':
      if ( ! ccUserInfo.appointment ) {
        errors.push( 'Please select a date and time' );
      }

      break;
    case 'call-me-now':
      if ( $( '#call-me-now-firstName' ).val() === '' ) {
        errors.push(' Please enter your first name.' );
      }

      if ( $( '#call-me-now-lastName' ).val() === '' ) {
        errors.push(' Please enter your last name.' );
      }

      if ( $( '#call-me-now-phone' ).val() === '' ) {
        errors.push(' Please enter your phone.' );
      }

      break;

    case 'page-call-me-later-submit':
      if ( ! $( '.field-agree-to-terms input' ).prop( 'checked' ) ) {
        errors.push( 'To continue, you must agree to the terms and conditions.' );
        $( '.field-agree-to-terms input' ).addClass( 'error' );
      }

      if ( global.ccUserInfo.appointment === null ) {
        errors.push( 'Please select a Date / Time.' );
      }
      break;

    default:

  }
  return errors;
}

function checkFor18AndOlder(){
  var dateOfBirth = $( '#patient-birth-date' ).val();
  var dsplit = dateOfBirth.split("/");
  var dateRegex = new RegExp("[0-9]{2}/[0-9]{2}/[0-9]{4}");
  var regexTest = dateOfBirth.match(dateRegex);
  if(regexTest == null){
    return false;
  }
  var checkDate = new Date(parseInt(dsplit[2])+18,dsplit[1]-1,dsplit[0]);
  var dateNow = new Date();
  console.log(checkDate);
  console.log(dateNow);
  if(checkDate <= dateNow){
    return true;
  }else{
    return false;
  }
}


//Submit w1 form (step3)
export function submitFormW1( formID ) {

  // todo: needs to come from callMeNowInit() : form.js
  let callCenterNumber = $(".trans_id").first().text() || '888-651-9950';

  var pageName = $( '.gform_page' ).last().attr( 'class' ).split( ' ' )[ 1 ];
  var errors = validateSection( pageName );
  // var submitButton = $( '#gform_submit_button_' + formID );
  var submitButton = $( '#temp_submit_button' );
  var $messageBox = $( '#webform-component-messages' );

  if (typeof global.ccUserInfo.attemptsAtSubmission === 'undefined') {
    ccUserInfo.attemptsAtSubmission = 0;
  }

  ccUserInfo.attemptsAtSubmission++;
    if (typeof ga !== 'undefined') {
      ga(function () {
        var tracker = ga.getAll()[0];
        global.ga.trackingId = tracker.get('trackingId');
        global.ga.clientId = tracker.get('clientId');
        global.ga.userId = Cookie.get('userId');
        global.ga.gclId = Cookie.get('cc-gclid');
      });
    } else {
      if(global.ga != undefined){
        global.ga.trackingId = global.ga.clientId = global.ga.userId = global.ga.gclId = '';
      }
    }
  
  if ( errors.length === 0 ) {
    submitButton.slideUp();

    showWaitingIcon( 'Validating Your Appointment' );

    if(ccUserInfo.guid.indexOf("s:") >= 0){
      var guidRegex = new RegExp("([a-z]|[A-Z]|[0-9]){18}");
      var formattedGuidArray = ccUserInfo.guid.match(guidRegex);
      ccUserInfo.guid = formattedGuidArray[0];
    }
    var selectedTimeBlockElement = $("#choose-date--timelist .choose-date-timelist ul > li.selected");

    ccUserInfo.appointmentHtmlInfo = "class = " + selectedTimeBlockElement.attr("class") + " \ntimeslot = " + selectedTimeBlockElement.data('timeslot') +
      "\ndatestring = " + selectedTimeBlockElement.data('datestring') + "\ntimestring = " + selectedTimeBlockElement.data('timestring') +
      "calendarstart = " + selectedTimeBlockElement.data('calendarstart') + "\ncalendarend = " + selectedTimeBlockElement.data('calendarend');

    var leadData = {
      action: 'cc_salesforce_validate_appointment',
      GUID: ccUserInfo.guid,
      ContactID: ccUserInfo.contactID,	//$contactIDField.val(),
      AccountID: ccUserInfo.accountID,	//$accountIDField.val(),
      IsRescheduling: ccUserInfo.reschedulingAppointment,
      AppointmentId: ccUserInfo.appointment,	//$("#edit-submitted-step-choose-date-select-a-time").val(),
      CenterId: ccUserInfo.centerID,	//$centerIDField.val(),
      FewQuestions: ccUserInfo.fewQuestions,
      FirstName: ccUserInfo.firstName,	//$firstNameField.val(),
      LastName: ccUserInfo.lastName,	//$lastNameField.val(),
      Phone: ccUserInfo.phone,	//helpers.format_phonenumber( $phonenumberfield.val(), null, 'US' ),
      Address: ccUserInfo.address,	//$streetAddressField.val() + " " + addressApt,
      City: ccUserInfo.city,	//$cityField.val(),
      State: ccUserInfo.state,	//$stateField.val(),
      Zip: ccUserInfo.zipcode,	//($zipcodeField.val() || $initialZipCodeField.val() )
      testing: window.ccUserInfo.testing,	//($zipcodeField.val() || $initialZipCodeField.val() )
      // securityToken : helpers.getCookie('securityToken'),
      securityToken: global.securityToken,
      leadSegment: ccUserInfo.leadSegment,
      appointmentHtmlInfo: ccUserInfo.appointmentHtmlInfo,
      model2PilotCenter: global.ccUserInfo.model2PilotCenter,
      birthDate: $( '#patient-birth-date' ).val(),
    };

      leadData.GACLIENTID = global.ga.clientId;
      leadData.GAUSERID = global.ga.userId;
      leadData.GATRACKID = global.ga.trackingId;
      leadData.GCLID = global.ga.gclId;
    


    let parsed = helpers.getAllQueryParams();
    if(parsed.model2testing !== undefined){
      leadData.model2testing = true;
    }

    var testLeadSegment = parsed.testLeadSegment;
    if(testLeadSegment !== undefined){
      leadData.testLeadSegment = testLeadSegment;
    }

    jQuery.ajax( {
      url: ajaxurl,
      data: leadData,
      cache: false,
      dataType: "json",
      type: "POST",
      success: function( result, success ) {
        if ( result.success === true ) {

          window[ 'gf_submitting_' + formID ] = true;
          $( 'form.schedule-consultation-form' ).submit();

          hideWaitingIcon();

        } else if ( result.message === 'No appts' ) {
          //console.log( 'Appointment was taken:' );
          $messageBox.find( '.message-text' ).html( 'Sorry, that appointment time was just taken by someone else.<br/> Please try a different time, or call<br/> ' + callCenterNumber + '<br/> for assistance.' );

          global.dataLayer.push({ "error_data": result });
          global.dataLayer.push({ "error_message": "Appointment was taken" });
          global.dataLayer.push({ "event": "ajax-error" });

          global.dataLayer.push({ 'appointmentnotavailable': true });
          global.dataLayer.push({ 'event': 'Appointment Not Available' });

          showMessageBox();
          submitButton.slideDown();
          hideWaitingIcon();
        } else {
          //console.log( 'Server Error validating appointment:' );
          $messageBox.find( '.message-text' ).html( 'Sorry, there was a problem scheduling your appointment. Try again later, or call<br/> ' + callCenterNumber + '<br/> for assistance.' );

          global.dataLayer.push({ "error_data": result });
          global.dataLayer.push({ "error_message": "Server Error validating appointment" });
          global.dataLayer.push({ "event": "ajax-error" });

          showMessageBox();
          submitButton.slideDown();
          hideWaitingIcon();
        }

      },
      error: function( result, error ) {

        $messageBox.find( '.message-text' ).html( 'Sorry, there was a problem scheduling your appointment. Try again later, or call<br/> ' + callCenterNumber + '<br/> for assistance.' );

        showMessageBox();
        submitButton.slideDown();
        //console.log( 'Ajax Error validating appointment.', 'Result: ', result, 'Error: ', error );
        hideWaitingIcon();

        global.dataLayer.push({ "error_data": result });
        global.dataLayer.push({ "error_message": "Ajax Error validating appointment." });
        global.dataLayer.push({ "event": "ajax-error" });
      },
    } );
  } else {
    var errorHTML = '<h4>Please fix the following:</h4>';
    for ( var i = 0; i < errors.length; i++ ) {
      errorHTML += '<p>' + errors[i] + '</p>';
    }
    $messageBox.find( '.message-text' ).html( errorHTML );
    showMessageBox();
  }
  return false;
}

export function schedulingDisabledPopup() {
  hideWaitingIcon();
  $('.form-page-step').css('display',"none");
  let schedDisabledCallCenterNumber = '8889788625';
  let schedDisabledCallCenterNumberFormatted = '888-978-8625';

  //set phone number to phone number for disabled scheduling
  $('.mobile-number a').html(schedDisabledCallCenterNumberFormatted);
  $('.mobile-number a').attr('href','tel:'+schedDisabledCallCenterNumber);
  $('#nav-sticky-phone-number').html(schedDisabledCallCenterNumberFormatted);
  $('#nav-sticky-phone-number').attr('href','tel:'+schedDisabledCallCenterNumber);
  //set to be persistent
  Cookie.set('campaign_phone_number', schedDisabledCallCenterNumber);

  var message = '<div id="sched-disabled-messaging">\
  <div id="sched-disabled-header"><h4>Please Call Us</h4></div>\
  <div id="sched-disabled-content">To secure the most convenient day and time possible for your consultation, please give us a call at\
  <a id="sched-disabled-phone" href="tel:'+schedDisabledCallCenterNumber+'">'+schedDisabledCallCenterNumberFormatted+'</a>.\
  We will save the information you provided here to assist you. Thank you.</div>\
  </div>';

  $('.page-datetime-confirm').html(message);

  $('.gform_body').attr('id','sched-disabled-window');
  global.dataLayer.push( { 'event': 'Schedule Disabled' } );
}

export function promptToCallWithScheduleDisplay(){
  let schedDisabledCallCenterNumber = '8889788625';
  let schedDisabledCallCenterNumberFormatted = '888-978-8625';

  //set phone number to phone number for disabled scheduling
  $('.mobile-number a').html(schedDisabledCallCenterNumberFormatted);
  $('.mobile-number a').attr('href','tel:'+schedDisabledCallCenterNumber);
  $('#nav-sticky-phone-number').html(schedDisabledCallCenterNumberFormatted);
  $('#nav-sticky-phone-number').attr('href','tel:'+schedDisabledCallCenterNumber);
  //set to be persistent
  Cookie.set('campaign_phone_number', schedDisabledCallCenterNumber);
  var message = '<p style="width: 80%;margin-left: 10%;margin-right: 10%;text-align: center;">Can\'t find an appointment that works with your schedule? Call us at <a style="color:#1a72b5 !important; text-decoration:none; font-weight:600;" id="calendar-phone-number-ptc" href="tel:'+schedDisabledCallCenterNumberFormatted+'">'+schedDisabledCallCenterNumberFormatted+'</a></p>';
  $('.make-appointment').prepend(message);
  $('#calendar-phone-number').html(schedDisabledCallCenterNumberFormatted);
  $('#calendar-phone-number').attr('href','tel:'+schedDisabledCallCenterNumber);
}

function savePromptToCallCampaign(){
  var promptToCallData = {};
  promptToCallData.GUID = global.ccUserInfo.contactID || global.ccUserInfo.guid;
  promptToCallData.isContact = ( global.ccUserInfo.contactID !== "" && global.ccUserInfo.contactID !== null ) ? true : false;
  promptToCallData.TransId = "onlineschedrestrictedpromptcall";
  promptToCallData.action = "cc_salesforce_submit_campaign_member";
  promptToCallData.securityToken = global.securityToken;
  jQuery.ajax({
    url: ajaxurl,
    data: promptToCallData,
    cache: false,
    dataType: "json",
    type: "POST",
    // success: function( result, success ) {
    //   //console.log( 'Success: ', result );
    // },
    // error: function( result, error ) {
    //   //console.log( 'Error: ', result );
    // }
  });
}

export function saveCampaign(campaignName){
  var promptToCallData = {};
  promptToCallData.GUID = global.ccUserInfo.contactID || global.ccUserInfo.guid;
  promptToCallData.isContact = ( global.ccUserInfo.contactID !== "" && global.ccUserInfo.contactID !== null ) ? true : false;
  promptToCallData.TransId = campaignName;
  promptToCallData.securityToken = global.securityToken;
  promptToCallData.action = "cc_salesforce_submit_campaign_member";

  jQuery.ajax({
    url: ajaxurl,
    data: promptToCallData,
    cache: false,
    dataType: "json",
    type: "POST",
    // success: function( result, success ) {
    //   //console.log( 'Success: ', result );
    // },
    // error: function( result, error ) {
    //   //console.log( 'Error: ', result );
    // }
  });
}

export function showPromptToCallMessageBox(){
  var promptToCallHtml = "<p>We're sorry, the date you've selected is unavailable for online scheduling. Please select another day or call <a href='tel:8889788625'>888-978-8625</a> so we can assist you.</p>";
  $('#webform-component-messages .message-text').html(promptToCallHtml);

  $('#webform-component-messages .buttons').find("> div").hide();
  var ok = $('<div class="temp">OK</div>').appendTo($('#webform-component-messages .buttons'));
  
  showMessageBox();

  ok.click(function(){
    hideMessageBox();
  });

  global.dataLayer.push( { 'event': 'Schedule Disabled' } );

  savePromptToCallCampaign();
}

export function initDateTimeField(){
  var dateFieldHtml = '<label for="patient-birth-date" class="left-padding-schedule-sidebar">Birth Date:</br>\
    <span style="font-size: .8em;color: #1d76b3;" class="tooltip left-padding-schedule-sidebar">Why should I provide my birthdate?</span>\
  </label><br><br>\
  <input type="text" inputmode="numeric" id="patient-birth-date" name="patient-birth-date" class="left-margin-schedule-sidebar" placeholder="mm/dd/yyyy">';

  $(dateFieldHtml).insertBefore( ".field-agree-to-terms" );
  $('.tooltip').tooltipster({
    theme: 'tooltipster-light',
    contentCloning: true,
    interactive: true,
    content:$('<span> Providing your birthdate allows you to securely fill<br> out forms online before your appointment. Your birthdate<br> will be used as a password to access these forms and<br> make your visit to the center as smooth and safe as possible. This information<br> is transmitted and stored securely, in accordance with<a href="/privacy-policy/" target="_blank"> our Privacy Policy</a>.</span>'),
  }); 
  $('#patient-birth-date').mask('99/99/9999',{placeholder:"mm/dd/yyyy"});
  saveCampaign('dob_webtest');
}