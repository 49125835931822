import queryString from 'query-string';
export function retrieveUnfilteredAppiontmentsNearest() {

	var ajaxData = {};
	ajaxData.action = 'cc_salesforce_get_all_nearest_appointments';
      ajaxData.securityToken = global.securityToken;

      var parsed = queryString.parse(location.search);
      if(parsed.model2testing !== undefined){
            ajaxData.model2testing = true;
      }

	$.ajax({
      type: 'POST',
      url: ajaxurl,
      dataType: 'json',
      contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
      data: ajaxData,
      success: function(msg) {
            if(msg != "appointments not retrieved"){
                  global.unfilteredAppointments = msg;
            } else{
                  global.unfilteredAppointments = '';
            }
      },
      error: function() {
      },
	});
}