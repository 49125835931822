import queryString from 'query-string';

export default {
  init() {
    console.log('test1');
    if($('.confirmation-email-handler').length > 0){
      console.log('test2');
      processAppointmentConfirmation();
    }

    let processAppointmentConfirmation = function(){
      var ajaxData = {};
      ajaxData.action = 'cc_salesforce_confirm_appointment';

      var parsed = queryString.parse(location.search);
      if(parsed.ID !== undefined){
        ajaxData.ID = true;
      }
      
      if(parsed.patientId !== undefined){
        ajaxData.patientId = parsed.patientId;
      }
      // ajaxData.requestId = getOauthRequestId();
      $.ajax({
        type: 'POST',
        url: ajaxurl,
        dataType: 'json',
        contentType: 'application/x-www-form-urlencoded; charset=UTF-8',
        data: ajaxData,
        success: function(msg) {
        },
        error: function() {
        },
      });
    } 
  },
};

